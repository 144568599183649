import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import googleIcon from '../../../assets/img/icon/google-icon.png';
import emailSvg from '../../../assets/img/icon/email.svg';
import heroImg0 from '../../../assets/img/hero-img0.png';
import heroImg1 from '../../../assets/img/hero-img1.png';
import heroImg2 from '../../../assets/img/hero-img2.png';
import heroImg3 from '../../../assets/img/hero-img3.png';
import heroImg4 from '../../../assets/img/hero-img4.png';
import heroImg5 from '../../../assets/img/hero-img5.png';
import logo from '../../../assets/img/logo_nobuffer.png';
import heroBg0 from '../../../assets/img/hero-bg0.png'
import heroBg1 from '../../../assets/img/hero-bg1.jpg';
import heroBg2 from '../../../assets/img/hero-bg2.jpg';
import heroBg3 from '../../../assets/img/hero-bg3.jpg';
import heroBg4 from '../../../assets/img/hero-bg4.png';
import heroBg5 from '../../../assets/img/hero-bg5.png';
import onelink from '../../../assets/svg/onelink_qr.svg';
function BootstrapModal2() {


    const handleDownloadApp = () => {
        window.open("https://onelink.to/8haqa2");
    }

    return (
        <>
            <div className='login-page'>
                <div class="row gx-0 align-items-center">
                <div class="col-lg-5">
                    <div class="login-box-height">
                        <div class="login-box text-center">
                            <div class="login-btn-sec text-center
                            ">
                                <img src={logo} alt="logo" width={"80%"} class="img-fluid"/>

                            </div>
                            <h4 class="login-title m-2 text-center"> Experience the best features and seamless functionality with our mobile app please.</h4>
                            <img src={onelink} alt="onelink" class="img-fluid" width={"400px"} />
                            <button className='btn theme-btn' onClick={handleDownloadApp}>Download App</button>
                            <br class="d-lg-block d-none"></br>
                          

                        </div>
                    </div>
                    
                    <div class="col text-center">
                                <small class="info-terms">
                                    <span>By using Amigotor you agree to the </span>
                                    <a style={{color:"#009688"}} href="https://transkriptor.com/terms-and-conditions/" target="_blank">Terms of Service</a>
                                    <span> and </span>
                                    <a style={{color:"#009688"}} href="https://transkriptor.com/privacy-policy/" target="_blank">Privacy Policy</a>
                                </small>
                            </div>
                </div>
                <div class="col-lg-7 d-lg-block d-none">
                    <div class="login-img-div white-color">
                        <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 0"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" class="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 5"></button>
                            </div>
                            <div class="carousel-inner">
                            <div class="carousel-item active">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg0})` }}>
                                        <div>
                                            <img src={heroImg0} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">Talk to Files</h2>
                                                <p>Transform Your Files into an Artifical Intelligence Guide</p>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item ">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg1})` }}>
                                        <div>
                                            <img src={heroImg1} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">Convert Audio To Text</h2>
                                                <p>Automatically transcribe your meetings, interviews, lectures, and other conversations.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg2})` }}>

                                        <div>
                                            <img src={heroImg2} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">Convert Text To Speech</h2>
                                                <p>Automatically convert text to speech (voice) with SPEAKTOR's artificial intelligence text reader.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg3})` }}>
                                        <div>
                                            <img src={heroImg3} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">AI Content Writer</h2>
                                                <p>Automatically generate different formats of text with Eskritor's artificial intelligence (AI) content writer.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg4})` }}>
                                        <div>
                                            <img src={heroImg4} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">Smart meeting recorder</h2>
                                                <p>Automate, transcribe, organize, and share meeting recordings.</p>
                                                <br/>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                <div class="hero-bg-img" style={{ backgroundImage: `url(${heroBg5})` }}>
                                        <div>
                                            <img src={heroImg5} class="img-fluid hero-img" alt="img"/>
                                            <div class="hero-content">
                                                <h2 class="mini-title mb-4">Screen Recoder</h2>
                                                <p>Record, Save, Transcribe and Share Instantly</p>
                                                <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}

export default BootstrapModal2;
